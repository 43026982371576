import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import classNames from 'classnames'

import { Modal } from 'pharmacy/src/display/modal'
import { Header2, Subtitle2, Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { MocProfileSubForm } from 'pharmacy/src/cme/mocProfileSubForm'
import { mocProfileFormValidationSchema } from 'pharmacy/src/cme/mocProfileSubForm/mocProfileSubFrom'

import { MOC_PROFILE_MODAL } from 'mednet-util/src/constants/modal'
import { mixpanel } from 'mednet-util/src/tracking'
import { MOC_STATUS } from 'mednet-util/src/constants/cme'

import { useUpdateMocProfile } from 'mednet-cns/src/hooks/cme'
import { useUserMocProfile } from 'mednet-cns/src/hooks/user'
import { useModalDrivers } from 'mednet-cns/src/hooks/modal'

import css from './mocProfileModal.scss'

const validationSchema = yup.object().shape(mocProfileFormValidationSchema)

const MocProfileForm = (props) => {
  const {
    isSubmitting,
    values,
    handleSubmit,
    submissionResult,
    handleNotNowButtonClick,
    setMocBoardStatus,
  } = props

  useEffect(() => {
    setMocBoardStatus({
      abimEnabled: values.abimEnabled,
      abpEnabled: values.abpEnabled,
    })
  }, [values.abimEnabled, values.abpEnabled])

  return (
    <Form>
      <div>
        <div className={css.mocProfileSubForm}>
          <MocProfileSubForm {...props} showValidationErrors />
        </div>

        <Subtitle3 className={classNames(css.row, css.checkboxRow)}>
          <Field
            type="checkbox"
            name="optOutMoc"
            value={values.optOutMoc}
            checked={values.optOutMoc}
          />
          I would like to opt-out of MOC for all future activities on theMednet
        </Subtitle3>
      </div>

      <div className={css.formFooter}>
        {submissionResult === false && (
          <div className={css.submittionErrorMessage}>Something went wrong</div>
        )}
        <div className={css.buttonsContainer}>
          <Button
            type="neutral"
            className={css.button}
            onClick={handleNotNowButtonClick}
          >
            Not now
          </Button>
          <Button
            className={css.button}
            onClick={handleSubmit}
            isLoading={isSubmitting}
          >
            Save
          </Button>
        </div>
        <Subtitle2 className={css.note}>
          *Note: if you do not provide this information by the end of the year,
          any MOC credits you have redeemed in 2022 will NOT be submitted to
          your board
        </Subtitle2>
      </div>
    </Form>
  )
}

const MocProfilePrompt = (props) => {
  const {
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    closeMocProfileModal,
  } = props

  const [userData, userRequest] = useUserMocProfile()
  const updateMocProfile = useUpdateMocProfile()
  const [submissionResult, setSubmissionResult] = useState(undefined)

  const [{ abimEnabled, abpEnabled }, setMocBoardStatus] = useState({
    abimEnabled: isAbimMocEnabledForUser,
    abpEnabled: isAbpMocEnabledForUser,
  })

  const initialValues = {
    abimNumber: userData.abim ?? '',
    abpNumber: userData.abp ?? '',
    birthDate: userData.birthDate ?? '',
    optOutMoc:
      userData.cmeAndMocAvailability?.mocStatus === MOC_STATUS.MOC_DISABLED,
    abimEnabled: isAbimMocEnabledForUser,
    abpEnabled: isAbpMocEnabledForUser,
    board: isAbimMocEnabledForUser ? 'abim' : 'abp',
  }

  useEffect(() => {
    mixpanel.track('Opened MOC profile prompt')
  }, [])

  const handleNotNowButtonClick = useCallback(() => {
    mixpanel.track('Clicked not now in MOC profile prompt')

    closeMocProfileModal()
  }, [closeMocProfileModal, userData])

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      let valuesToSubmit = {}

      if (values.optOutMoc) {
        valuesToSubmit = {
          optOutMoc: values.optOutMoc,
        }
        mixpanel.track('Clicked Opt-out MOC From MOC profile modal')
      } else {
        valuesToSubmit = values
        mixpanel.track('Clicked Save From MOC profile modal')
      }

      updateMocProfile(valuesToSubmit, (res) => {
        setSubmitting(false)
        if (res.success) {
          mixpanel.track('Successfully updated data in MOC profile prompt')
          closeMocProfileModal()
        } else {
          mixpanel.track('Failed to update data in MOC profile prompt')
          setSubmissionResult(res.success)
        }
      })
    },
    [updateMocProfile, closeMocProfileModal, setSubmissionResult]
  )

  const boardText =
    abimEnabled && abpEnabled
      ? ' ABIM & ABP'
      : abimEnabled
      ? ' ABIM'
      : abpEnabled
      ? ' ABP'
      : ''

  if (!userRequest.isLoaded) {
    return <StarLoader />
  }

  return (
    <div>
      <Header2 className={css.title}>Information for MOC points</Header2>
      <hr />
      <Subtitle2 className={css.promptDetails}>
        To claim MOC points for your redeemed activities, please provide your
        Board ID number and date of birth (required for all boards). By
        completing the below, you consent to allow your information to be shared
        with the University of Chicago in order to report your credit to
        {boardText}. You only need to fill in this information once; you will
        not be asked again.
      </Subtitle2>
      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {(props) => (
          <MocProfileForm
            {...props}
            handleNotNowButtonClick={handleNotNowButtonClick}
            submissionResult={submissionResult}
            setMocBoardStatus={setMocBoardStatus}
          />
        )}
      </Formik>
    </div>
  )
}

export const MocProfileModal = (props) => {
  const [openMocProfileModal, closeMocProfileModal] = useModalDrivers(
    MOC_PROFILE_MODAL.modalId
  )

  useEffect(() => {
    sessionStorage.getItem('promptedForMoc') !== 'true' && openMocProfileModal()
  }, [])

  return (
    <Modal
      modalId={MOC_PROFILE_MODAL.modalId}
      {...props}
      contentClassName={css.modalContainer}
      closeOnBackdrop={false}
      closeMocProfileModal={() => {
        sessionStorage.setItem('promptedForMoc', 'true')
        closeMocProfileModal()
      }}
      size="large"
    >
      <MocProfilePrompt />
    </Modal>
  )
}
