import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import classNames from 'classnames'
import { Redirect } from 'react-router'

import { Subtitle3 } from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { MocProfileSubForm } from 'pharmacy/src/cme/mocProfileSubForm'
import { mocProfileFormValidationSchema } from 'pharmacy/src/cme/mocProfileSubForm/mocProfileSubFrom'

import { mixpanel } from 'mednet-util/src/tracking'
import { MOC_STATUS } from 'mednet-util/src/constants/cme'
import {
  useCmeAndMocAvailabilityCheck,
  useUpdateMocProfileById,
} from 'mednet-cns/src/hooks/cme'
import { useUser, useUserMocProfileById } from 'mednet-cns/src/hooks/user'
import { authItems } from 'mednet-util/src/constants/permission'
import { isUserPermittedTo } from 'mednet-util/src/permission'

import { UserProfileUpdateTemplate } from './userProfileUpdateTemplate'

import css from './userMoc.scss'

const validationSchema = yup.object().shape(mocProfileFormValidationSchema)

const MocProfileForm = (props) => {
  const { isSubmitting, values, handleSubmit, submissionResult } = props

  const [showValidationErrors, setShowValidationErrors] = useState(false)

  useEffect(() => {
    setShowValidationErrors(false)
  }, [values])

  return (
    <Form className={css.formConatiner}>
      <div>
        <MocProfileSubForm
          {...props}
          showValidationErrors={showValidationErrors}
          useLegacyStyle
        />

        <Subtitle3 className={classNames(css.row, css.optOutRow)}>
          <Field
            type="checkbox"
            name="optOutMoc"
            value={values.optOutMoc}
            checked={values.optOutMoc}
          />
          I would like to opt-out of MOC for all future activities on theMednet
        </Subtitle3>
      </div>

      <div className={css.formFooter}>
        {submissionResult === false && (
          <div className={css.submittionErrorMessage}>Something went wrong</div>
        )}
        <Button
          className={css.button}
          onClick={(...args) => {
            setShowValidationErrors(true)
            handleSubmit(args)
          }}
          isLoading={isSubmitting}
          icon={['fas', 'check']}
        >
          Save
        </Button>
      </div>
    </Form>
  )
}

const UserMoc = ({ userId, history, location }) => {
  useEffect(() => {
    mixpanel.track('Visited MOC profile page')
  }, [])

  const {
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    isCmeAndMocAvailabilityCheckLoading,
    isCmeAndMocAvailabilityCheckLoaded,
  } = useCmeAndMocAvailabilityCheck(userId)

  const [userMocData, userMocRequest] = useUserMocProfileById(userId)

  const updateMocProfile = useUpdateMocProfileById(userId)
  const [submissionResult, setSubmissionResult] = useState(undefined)

  const initialValues = {
    abimNumber: userMocData?.abim ?? '',
    abpNumber: userMocData?.abp ?? '',
    birthDate: userMocData?.birthDate ?? '',
    optOutMoc:
      userMocData?.cmeAndMocAvailability?.mocStatus === MOC_STATUS.MOC_DISABLED,
    abimEnabled: isAbimMocEnabledForUser,
    abpEnabled: isAbpMocEnabledForUser,
  }

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      updateMocProfile(values, (res) => {
        setSubmitting(false)
        if (res.success) {
          mixpanel.track('Successfully updated data in MOC profile page')
          history.push(`/user/user/view/id/${userId}`)
        } else {
          mixpanel.track('Failed to update data in MOC profile page')
          setSubmissionResult(res.success)
        }
      })
    },
    [userId, history, setSubmissionResult]
  )

  if (
    !userMocRequest.isLoaded ||
    isCmeAndMocAvailabilityCheckLoading ||
    !isCmeAndMocAvailabilityCheckLoaded
  ) {
    return <StarLoader />
  }

  if (!userMocData.isMocAvailableByDefault) {
    return <Redirect to={`/user/user/view/id/${userId}`} push />
  }

  return (
    <UserProfileUpdateTemplate
      pageHeader="Edit CME Information"
      location={location}
    >
      <div className={css.details}>
        <div>
          theMednet is proud to offer CME credit & MOC points to our physician
          community. MOC points for qualifying specialties will be reported by
          the University of Chicago at year end.
        </div>
        <div>
          The below information is required by ABIM and ABP maintenance of
          certification (MOC) points to be officially submitted. You may opt in
          or out of MOC at any time.
        </div>
      </div>

      <Formik
        validationSchema={validationSchema}
        initialValues={initialValues}
        onSubmit={handleFormSubmit}
      >
        {(props) => (
          <MocProfileForm {...props} submissionResult={submissionResult} />
        )}
      </Formik>
    </UserProfileUpdateTemplate>
  )
}

const UserMocPage = (props) => {
  const { userId } = props.match.params
  const [{ userData, permissions }, userRequest] = useUser()
  const selfUserId = userData?.userId
  const viewUserId = userId ? parseInt(userId) : selfUserId

  if (!userRequest.isLoaded) {
    return <StarLoader />
  }
  const viewingDifferentUser = selfUserId !== viewUserId
  const userIsAdmin = isUserPermittedTo(permissions, [authItems.admin])
  const notAuthorized = viewingDifferentUser && !userIsAdmin

  if (notAuthorized) {
    return <Redirect to={`/user/user/view/id/${viewUserId}`} push />
  }

  // make sure not to call this component until userId is available
  return <UserMoc userId={viewUserId} {...props} />
}

export default UserMocPage
