import React, { useCallback, useEffect, useState } from 'react'
import * as yup from 'yup'
import { Formik, Field, Form } from 'formik'
import classNames from 'classnames'
import isEmpty from 'lodash/isEmpty'

import {
  Header2,
  Subtitle1,
  Subtitle2,
  Subtitle3,
} from 'pharmacy/src/typography'
import { Button } from 'pharmacy/src/input/button'
import { StarLoader } from 'pharmacy/src/misc/loaders/starLoader'
import { Icon } from 'pharmacy/src/display/icon'
import { MocProfileSubForm } from 'pharmacy/src/cme/mocProfileSubForm'
import { RedmptionRelatedQuestions } from 'pharmacy/src/cme/redemptionRelatedQuestions'

import { mixpanel } from 'mednet-util/src/tracking'

import { useRedeemMocActivities } from 'mednet-cns/src/hooks/cme'
import { useUserMocProfile } from 'mednet-cns/src/hooks/user'

import {
  CME_ACTIVITY_TYPE,
  CME_ACTIVITY_TYPE_LABEL,
  MOC_STATUS,
} from 'mednet-util/src/constants/cme'

import { mocProfileFormValidationSchema } from '../mocProfileSubForm/mocProfileSubFrom'

import css from './mocAssessmentForm.scss'

const validationSchema = yup.object().shape({
  ...mocProfileFormValidationSchema,
  reflections: yup.string().when(['abimEnabled', 'abpEnabled', 'optOutMoc'], {
    is: (abimEnabled, abpEnabled, optOutMoc) =>
      (abimEnabled || abpEnabled) && !optOutMoc,
    then: yup
      .string()
      .required('Required')
      .min(
        100,
        'Your answer does not meet the threshold for completeness. Please provide a complete and thorough reflection.'
      ),
  }),
  allowSharingInfo: yup
    .bool()
    .when(['abimEnabled', 'abpEnabled', 'optOutMoc'], {
      is: (abimEnabled, abpEnabled, optOutMoc) =>
        (abimEnabled || abpEnabled) && !optOutMoc,
      then: yup.boolean().oneOf([true], 'Required'),
    }),
})

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const MocAssessmentInternalForm = (props) => {
  const {
    isSubmitting,
    values,
    handleSubmit,
    submissionResult,
    touched,
    errors,
    onExit,
    handleCancelingMocAssessment,
    setSubmissionResult,
    showRelatedQuestions,
    isAfterCmeRedemption,
    setMocBoardStatus,
  } = props

  const reflectionsErrorMsg = getValidationTag('reflections', touched, errors)

  const reflectionsLengthLabelClasses = classNames(css.reflectionsLengthLabel, {
    [css.reflectionsLengthLabelWithError]:
      reflectionsErrorMsg && reflectionsErrorMsg !== 'Required',
  })

  const notNowButtonHandler = useCallback(
    isAfterCmeRedemption && showRelatedQuestions
      ? handleCancelingMocAssessment(() =>
          setSubmissionResult({
            success: true,
          })
        )
      : handleCancelingMocAssessment(onExit),
    [isAfterCmeRedemption, showRelatedQuestions]
  )

  useEffect(() => {
    setMocBoardStatus({
      abimEnabled: values.abimEnabled,
      abpEnabled: values.abpEnabled,
    })
  }, [values.abimEnabled, values.abpEnabled])

  return (
    <Form>
      <div>
        <div className={css.reflectionsContainer}>
          <Subtitle1>
            How, if at all, did this activity help you overcome any barriers or
            challenges that you encounter in your care of patients or practice?
            If it did not, please explain how you used this information.
          </Subtitle1>
          <div className={css.reflectionsInputContainer}>
            <Field
              component="textarea"
              name="reflections"
              className={classNames(css.reflections, {
                [css.error]: reflectionsErrorMsg,
              })}
              rows="8"
              disabled={
                values.optOutMoc || (!values.abimEnabled && !values.abpEnabled)
              }
            />
            <div className={reflectionsLengthLabelClasses}>
              ({values.reflections.length}/100)
            </div>
          </div>

          <div className={css.fieldError}>{reflectionsErrorMsg}</div>
        </div>

        <div className={css.mocProfileSubForm}>
          <MocProfileSubForm {...props} showValidationErrors />
        </div>
        <Subtitle2 className={css.checkboxRow}>
          <div className={css.allowSharingInfoInput}>
            <Field
              type="checkbox"
              name="allowSharingInfo"
              value={values.allowSharingInfo}
              checked={values.allowSharingInfo}
              disabled={values.optOutMoc}
            />
            <div className={css.allowSharingInfoError}>
              {getValidationTag('allowSharingInfo', touched, errors)}
            </div>
          </div>
          I consent to allow my information to be shared with the University of
          Chicago in order to report my credit to{' '}
          {values.abimEnabled &&
            "American Board of Internal  Medicine's (ABIM)"}
          {values.abimEnabled && values.abpEnabled && ' & '}
          {values.abpEnabled && "American Board of Pediatrics' (ABP)"} MOC
          program
        </Subtitle2>
      </div>

      <div className={css.formFooter}>
        {submissionResult.success === false && (
          <div className={css.submittionErrorMessage}>
            {submissionResult.message
              ? submissionResult.message
              : 'Something went wrong'}
          </div>
        )}
        <div className={css.buttonsContainer}>
          <Button
            type="neutral"
            className={css.button}
            onClick={notNowButtonHandler}
          >
            Not now
          </Button>
          <Button
            className={css.button}
            onClick={handleSubmit}
            isLoading={isSubmitting}
            isDisabled={
              !values.optOutMoc && !values.abimEnabled && !values.abpEnabled
            }
          >
            {values.optOutMoc ? 'Opt-out MOC' : 'Redeem MOC'}
          </Button>
        </div>
        <Subtitle3 className={classNames(css.checkboxRow, css.optOutMoc)}>
          <Field
            type="checkbox"
            name="optOutMoc"
            value={values.optOutMoc}
            checked={values.optOutMoc}
          />
          I would like to opt-out of MOC for all future activities on theMednet
        </Subtitle3>
      </div>
    </Form>
  )
}

export const MocAssessmentForm = (props) => {
  const {
    isAbimMocEnabledForUser,
    isAbpMocEnabledForUser,
    title,
    activities,
    creditsPerActivity,
    maxCreditsPerYear,
    isAfterCmeRedemption,
    showRelatedQuestions,
    onExit,
    showAsCard,
    location,
  } = props

  const [userData, userRequest] = useUserMocProfile()
  const redeemMocActivities = useRedeemMocActivities()
  const [submissionResult, setSubmissionResult] = useState({
    success: undefined,
    message: undefined,
    redeemedActivitiesIds: [],
  })

  const [{ abimEnabled, abpEnabled }, setMocBoardStatus] = useState({
    abimEnabled: isAbimMocEnabledForUser,
    abpEnabled: isAbpMocEnabledForUser,
  })

  const initialValues = {
    abimNumber: userData.abim ?? '',
    abpNumber: userData.abp ?? '',
    birthDate: userData.birthDate ?? '',
    optOutMoc:
      userData.cmeAndMocAvailability?.mocStatus === MOC_STATUS.MOC_DISABLED,
    abimEnabled: isAbimMocEnabledForUser,
    abpEnabled: isAbpMocEnabledForUser,
    reflections: '',
    allowSharingInfo: true,
  }

  const handleFormSubmit = useCallback(
    (values, { setSubmitting }) => {
      let valuesToSubmit = {}

      if (values.optOutMoc) {
        valuesToSubmit = {
          optOutMoc: values.optOutMoc,
        }

        mixpanel.track('Clicked Opt-out MOC From MOC Assessment', {
          location,
        })
      } else {
        valuesToSubmit = {
          ...values,
          activitiesIds: activities.map((activity) => activity.cmeActivityId),
        }

        mixpanel.track('Clicked Redeem MOC From MOC Assessment', {
          location,
        })
      }

      redeemMocActivities(valuesToSubmit, (res) => {
        setSubmitting(false)

        if (res.success) {
          if (
            (!isEmpty(res.redeemedActivities) || isAfterCmeRedemption) &&
            showRelatedQuestions &&
            !values.optOutMoc
          ) {
            const redeemedActivitiesIds = res.redeemedActivities.map(
              (activity) => activity.cmeActivityId
            )
            setSubmissionResult({
              success: res.success,
              redeemedActivitiesIds,
              message:
                redeemedActivitiesIds.length !== activities.length // Normally this should not happen, this may happen if the user did a strange behaviour like openning the redeemed page in a new tab in browser, redeeming MOC, and going back to this one
                  ? res.redeemedMaxCmeForCurrentYear
                    ? `Note that you did not redeem the full ${
                        activities.length * creditsPerActivity
                      } MOC credits because you hit the maximum  ${maxCreditsPerYear} MOC credits for this year.`
                    : `Note that you did not redeem the full ${
                        activities.length * creditsPerActivity
                      } MOC credits because for some of the content, MOC credit had already been claimed.`
                  : undefined,
            })
          } else {
            onExit()
          }
        } else {
          setSubmissionResult({
            success: res.success,
            redeemedActivitiesIds: [],
            message: res.alreadyRedeemed
              ? `MOC credits were already redeemed for ${
                  activities.length > 1 ? 'these activities' : 'this activity'
                } this year.`
              : res.redeemedMaxCmeForCurrentYear
              ? 'Maximum MOC credits were redeemed for this year.'
              : res.cmeNotRedeemed
              ? `MOC credits cannot be redeemed for ${
                  activities.length > 1 ? 'these activities' : 'this activity'
                } as CME is not redeemed for  ${
                  activities.length > 1 ? 'them' : 'it'
                } this year`
              : undefined,
          })
        }

        mixpanel.track('redeemed_moc', {
          success: res.success,
          activities: activities.map((activity) => ({
            activity_type: CME_ACTIVITY_TYPE_LABEL[activity.contentType],
            location,
            question_id:
              activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW
                ? undefined
                : activity.contentId,
            publication_id:
              activity.contentType === CME_ACTIVITY_TYPE.PUBLICATION_VIEW
                ? activity.contentId
                : undefined,
          })),
        })
      })
    },
    [
      showRelatedQuestions,
      isAfterCmeRedemption,
      redeemMocActivities,
      setSubmissionResult,
      activities,
      onExit,
      location,
    ]
  )

  const handleCancelingMocAssessment = useCallback(
    (callback) => () => {
      mixpanel.track('Skipped MOC assessment', {
        location,
      })
      callback()
    },
    [location]
  )

  const boardText =
    abimEnabled && abpEnabled
      ? ' ABIM & ABP'
      : abimEnabled
      ? ' ABIM'
      : abpEnabled
      ? ' ABP'
      : ''

  useEffect(() => {
    mixpanel.track('Opened MOC Assessment Form', {
      location,
    })
  }, [])

  if (submissionResult.success) {
    return (
      <RedmptionRelatedQuestions
        redeemedCmeActivitiesIds={
          isAfterCmeRedemption
            ? activities.map((activity) => activity.cmeActivityId)
            : []
        }
        redeemedMocActivitiesIds={submissionResult.redeemedActivitiesIds}
        discripancyExplainingMessage={submissionResult.message}
        creditsPerActivity={creditsPerActivity}
        onExit={onExit}
        showAsCard={showAsCard}
      />
    )
  }

  return (
    <div className={classNames({ [css.cardContainer]: showAsCard })}>
      {showAsCard && (
        <Icon
          icon="times"
          className={css.closeIcon}
          onClick={handleCancelingMocAssessment(onExit)}
        />
      )}
      <Header2 className={css.title}>{title(boardText)}</Header2>
      <hr />
      {!userRequest.isLoaded && <StarLoader />}
      <Subtitle2 className={css.promptDetails}>
        To claim {boardText} MOC points, please provide a complete and thorough
        response below as well as your Board Unique ID number and date of birth
        (required for all boards).
      </Subtitle2>
      {userRequest.isLoaded && (
        <Formik
          validationSchema={validationSchema}
          initialValues={initialValues}
          onSubmit={handleFormSubmit}
        >
          {(props) => (
            <MocAssessmentInternalForm
              {...props}
              submissionResult={submissionResult}
              onExit={onExit}
              handleCancelingMocAssessment={handleCancelingMocAssessment}
              setSubmissionResult={setSubmissionResult}
              showRelatedQuestions={showRelatedQuestions}
              isAfterCmeRedemption={isAfterCmeRedemption}
              setMocBoardStatus={setMocBoardStatus}
            />
          )}
        </Formik>
      )}
    </div>
  )
}
