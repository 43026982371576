import React, { useCallback } from 'react'
import moment from 'moment'
import { Field } from 'formik'
import isEmpty from 'lodash/isEmpty'
import * as yup from 'yup'

import { TextInput } from 'pharmacy/src/input/textInput'
import { DayMonthPicker } from 'pharmacy/src/input/datePicker'
import { Link } from 'pharmacy/src/navigation/link'
import { Icon } from 'pharmacy/src/display/icon'

import classNames from 'classnames'

import css from './mocProfileSubFrom.scss'

export const mocProfileFormValidationSchema = {
  abimNumber: yup.string().when(['optOutMoc', 'abimEnabled'], {
    is: (optOutMoc, abimEnabled) => !optOutMoc && abimEnabled,
    then: yup
      .string()
      .required('Required')
      .matches(/^\d+$/, 'ABIM must contain digits only.')
      .test({
        name: 'is-6-digits',
        skipAbsent: true,
        test(value, ctx) {
          if (!value?.trim()) return 'Required'

          if (!/^\d{6}$/.test(value)) {
            if (/^\d{1,5}$/.test(value)) {
              return ctx.createError({
                message: `ABIM must be 6 digits. Did you mean "${value.padStart(
                  6,
                  '0'
                )}"?`,
              })
            }

            return ctx.createError({
              message: `ABIM must be 6 digits.`,
            })
          }

          return true
        },
      }),
  }),
  abpNumber: yup.string().when(['optOutMoc', 'abpEnabled'], {
    is: (optOutMoc, abpEnabled) => !optOutMoc && abpEnabled,
    then: yup
      .string()
      .required('Required')
      .matches(/^\d+$/, 'ABP must contain digits only.')
      .test({
        name: 'is-at-least-4-digits',
        skipAbsent: true,
        test(value, ctx) {
          if (!value?.trim()) return 'Required'

          if (!/^\d{4,}$/.test(value)) {
            return ctx.createError({
              message: `ABP must be at least 4 digits. Did you mean "${value.padStart(
                4,
                '0'
              )}"?`,
            })
          }

          return true
        },
      }),
  }),
  birthDate: yup.string().when(['optOutMoc', 'abimEnabled', 'abpEnabled'], {
    is: (optOutMoc, abimEnabled, abpEnabled) =>
      !optOutMoc && (abimEnabled || abpEnabled),
    then: yup
      .string()
      .required('Required')
      .matches(/^\d{2}\/\d{2}$/, 'Should be MM/DD'),
  }),
}

const getValidationTag = (fieldName, touched, errors) =>
  touched[fieldName] && errors[fieldName] ? errors[fieldName] : undefined

const MocIdField = ({ board, touched, errors, values, lookupPathname }) => {
  const validationErrorMsg = getValidationTag(`${board}Number`, touched, errors)
  return (
    <div className={classNames(css.row, { [css.error]: validationErrorMsg })}>
      <div
        className={classNames(css.fieldLabel, {
          [css.error]: validationErrorMsg,
        })}
      >
        <Field
          type="checkbox"
          name={`${board}Enabled`}
          value={values[`${board}Enabled`]}
          disabled={values.optOutMoc}
          checked={values[`${board}Enabled`]}
        />
        {board.toUpperCase()}:
      </div>

      <div className={css.fieldInput}>
        <div
          className={classNames(css.textFieldContainer, {
            [css.error]: validationErrorMsg,
          })}
        >
          <div
            className={classNames(css.fieldSecondaryLabel, {
              [css.error]: validationErrorMsg,
            })}
          >
            ID Number
          </div>
          <Field name={`${board}Number`}>
            {({ field }) => (
              <TextInput
                {...field}
                typeStyle="secondary"
                showClear={false}
                onChangeHandlesEvent
                placeholder="xxxxxx"
                disabled={values.optOutMoc || !values[`${board}Enabled`]}
              />
            )}
          </Field>
          {validationErrorMsg && (
            <div className={css.fieldError}>
              <div>{validationErrorMsg}</div>
            </div>
          )}
        </div>

        <Link
          external
          pathname={lookupPathname}
          className={classNames(css.lookupLink, {
            [css.error]: validationErrorMsg,
          })}
          target="_blank"
        >
          <Icon icon={['fas', 'search']} className={css.searchIcon} />
          <div>Look up</div>
        </Link>
      </div>
    </div>
  )
}

const BirthDateField = ({
  setFieldValue,
  values,
  touched,
  errors,
  disabled,
}) => {
  const fieldName = 'birthDate'

  const handleBirthDateChange = useCallback(
    (value) => {
      setFieldValue(fieldName, moment(value).format('MM/DD'))
    },
    [setFieldValue]
  )

  const validationErrorMsg = getValidationTag('birthDate', touched, errors)

  return (
    <div className={classNames(css.row, { [css.error]: validationErrorMsg })}>
      <div
        className={classNames(css.fieldLabel, {
          [css.error]: validationErrorMsg,
        })}
      >
        Date of Birth:
      </div>
      <div className={css.fieldInput}>
        <div
          className={classNames(css.datePicker, {
            [css.error]: validationErrorMsg,
          })}
        >
          <Field name={fieldName}>
            {({ field }) => (
              <DayMonthPicker
                inputProps={{
                  name: field.name,
                  readOnly: true,
                  disabled,
                }}
                onChange={handleBirthDateChange}
                stringValue={values[fieldName]}
                noDefaultValue={isEmpty(values[fieldName])}
              />
            )}
          </Field>
        </div>

        {validationErrorMsg && (
          <div className={css.fieldError}>{validationErrorMsg}</div>
        )}
      </div>
    </div>
  )
}

export const MocProfileSubForm = ({
  touched,
  errors,
  values,
  setFieldValue,
  showValidationErrors,
  useLegacyStyle,
}) => {
  return (
    <div className={classNames({ [css.legacySubForm]: useLegacyStyle })}>
      <BirthDateField
        setFieldValue={setFieldValue}
        values={values}
        touched={touched}
        errors={showValidationErrors ? errors : {}}
        disabled={values.optOutMoc}
      />

      <MocIdField
        board="abim"
        touched={touched}
        errors={showValidationErrors ? errors : {}}
        lookupPathname="https://www.abim.org/verify-physician"
        values={values}
      />

      <MocIdField
        board="abp"
        touched={touched}
        errors={showValidationErrors ? errors : {}}
        lookupPathname="https://www.abp.org/verification-certification"
        values={values}
      />
    </div>
  )
}
